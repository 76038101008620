<template>
  <div class="repair-parameters-wrapper">
    <el-form :model="config" :rules="configRules" ref="configForm" class="main" size="medium" label-width="135px" :label-position="'right'">
        <div class="form-block">
          <h3>派单配置</h3>
          <el-divider></el-divider>
          <div class="form-group">
            <el-row>
              <el-col :span="8">
                <el-form-item label="派单模式：">
                  <el-radio-group v-model="config.sendOrderType">
                    <el-radio  :label="1">手动派单</el-radio>
                    <el-radio  :label="2">自动派单</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="config.sendOrderType==2">
                <el-form-item label="匹配规则：">
                  <el-radio-group v-model="config.matchType">
                    <el-radio :label="1">顺序优先</el-radio>
                    <el-radio :label="2">满意度优先</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="form-group">
            <el-form-item label="工单审核：">
              <el-switch v-model="config.isAudit"></el-switch>
              <span class="mgl10">{{ config.isAudit?"开启":"关闭"}}</span>
            </el-form-item>
          </div>
        </div>
        <div class="form-block">
          <h3>工单配置</h3>
          <el-divider></el-divider>
          <div class="form-group">
            <label class="left-label">结束节点配置：</label>
            <el-steps :active="activeStep"  class="step-block" align-center>
              <el-step title="商户评价" icon="none">
                <template slot="icon">
                  <span class="solid-ring" @click="setStep(1)"></span>
                </template>
                <template slot="description">
                  <el-form-item prop="evaluationTimeout" label-width="0px" title="单位/小时">
                    <label>自动结束时间</label>
                    <el-input  v-model.number="config.evaluationTimeout" size="medium"  @keydown.native="$reconava.filterDecimalPoint" @keyup.native="$reconava.filterDecimalPoint"></el-input>
                    <span class="colorGrey pdl5">小时</span>
                  </el-form-item>
                </template>
              </el-step>
              <el-step title="物业确认" icon="none">
                <template slot="icon">
                  <span class="solid-ring"  @click="setStep(2)"></span>
                </template>
                <template slot="description">
                  <el-form-item prop="propertyAuditTimeout" label-width="0px" title="单位/小时">
                    <label>自动结束时间</label>
                    <el-input v-model.number="config.propertyAuditTimeout" size="medium" :disabled="activeStep<2"  @keydown.native="$reconava.filterDecimalPoint" @keyup.native="$reconava.filterDecimalPoint"></el-input>
                    <span class="colorGrey pdl5">小时</span>
                  </el-form-item>
                </template>
              </el-step>
              <el-step title="运营确认" icon="none">
                <template slot="icon">
                  <span class="solid-ring"  @click="setStep(3)"></span>
                </template>
                <template slot="description">
                  <el-form-item prop="operationAuditTimeout" label-width="0px" title="单位/小时">
                    <label>自动结束时间</label>
                    <el-input v-model.number="config.operationAuditTimeout" size="medium" :disabled="activeStep!='3'" @keydown.native="$reconava.filterDecimalPoint" @keyup.native="$reconava.filterDecimalPoint"></el-input>
                    <span class="colorGrey pdl5">小时</span>
                  </el-form-item>
                </template>
              </el-step>
            </el-steps>
          </div>
        </div>
        <div class="form-block">
          <h3>报修配置</h3>
          <el-divider></el-divider>
          <div class="form-group">
            <el-row>
              <el-col :span="8">
                <el-form-item label="催单间隔时间：" prop="urgeIntervalTime" title="单位/分钟">
                  <el-input v-model.number="config.urgeIntervalTime" @keydown.native="$reconava.filterDecimalPoint" @keyup.native="$reconava.filterDecimalPoint"></el-input>
                  <span class="colorGrey pdl5">分钟</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="工人最大接单数：" prop="maxOrderCount">
                  <el-input v-model.number="config.maxOrderCount" @keydown.native="$reconava.filterDecimalPoint" @keyup.native="$reconava.filterDecimalPoint"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="form-block">
          <h3>支付配置</h3>
          <el-divider></el-divider>
          <div class="form-group">
            <el-form-item label="是否有偿：">
              <el-switch v-model="config.isPayment" @change="changeIsPay"></el-switch>
              <span class="mgl10">{{ config.isPayment?"是":"否"}}</span>
            </el-form-item>
            <el-form-item label="线上支付：">
              <el-switch v-model="payOnline" :disabled="!config.isPayment"></el-switch>
              <span class="mgl10">{{!config.payOffline?"开启":"关闭"}}</span>
            </el-form-item>
          </div>
        </div>
    </el-form>
    <div class="footer button-group fixed-bottom-btn">
      <el-button class="btn-submit" size="medium" type="primary" @click="submit" :loading="isSubmitting">保存</el-button>
    </div>
  </div>
</template>

<script>
const validateNumber = function(min,max){
  let validator = (rule,value,callback)=>{
    if(value<min){
      return callback(new Error(`不能小于${min}`))
    }else if(max&&value>max){
      return callback(new Error(`不能大于${max}`))
    }else{
      callback()
    }
  }
  return  validator
}
export default {
  name: "repair-parameters",
  data(){
    return {
      config:{
        //  工单支付改成默认开启
        isPayment:true,
        payOffline:true
      },
      activeStep:1,
      isSubmitting:false,
      configRules:{
        evaluationTimeout:[this.$formValidator.empty('结束时间','blur','number'),{ validator:validateNumber(1,99), trigger:'blur',type:'number'}],
        urgeIntervalTime:[this.$formValidator.empty('催单间隔时间','blur','number'),{ validator:validateNumber(10,99), trigger:'blur',type:'number'}],
        maxOrderCount:[this.$formValidator.empty('最大接单数','blur','number'),{ validator:validateNumber(1,99), trigger:'blur',type:'number'}],
        propertyAuditTimeout:[
          this.$formValidator.empty('结束时间','blur','number'),
          { validator:validateNumber(1,99), trigger:'blur',type:'number'}],
        operationAuditTimeout:[this.$formValidator.empty('结束时间','blur','number'),{ validator:validateNumber(1,99), trigger:'blur',type:'number'}]
      }
    }
  },
  mounted(){
    this.getRepairParameters()
  },
  computed:{
    payOnline:{
      get(){
        return !this.config.payOffline
      },
      set(val){
        this.config.payOffline = !val
      }
    }
  },
  methods:{
    setStep(step){
      if(this.activeStep==step&&step>1){
        this.activeStep = (parseInt(step)-1)
      }else{
        this.activeStep = step
      }
      switch (this.activeStep){
        case 1:
          this.config.isPropertyAudit = false;
          this.config.isOperationAudit = false;
          this.config.operationAuditTimeout = '';
          this.config.propertyAuditTimeout = '';
          break;
        case 2:
          this.config.isPropertyAudit = true;
          this.config.isOperationAudit = false;
          this.config.operationAuditTimeout = '';
          break;
        case 3:
          this.config.isPropertyAudit = true;
          this.config.isOperationAudit = true;
      }
      if(!this.config.isPropertyAudit) {
        this.$refs.configForm.clearValidate('propertyAuditTimeout')
        delete this.configRules.propertyAuditTimeout;
      }else{
        this.$set(this.configRules,'propertyAuditTimeout',[
          this.$formValidator.empty('结束时间','blur','number'),
          { validator:validateNumber(1,99), trigger:'blur',type:'number'}])
      }
      if(!this.config.isOperationAudit){
        this.$refs.configForm.clearValidate('operationAuditTimeout')
        delete this.configRules.operationAuditTimeout;
      }else{
        this.$set(this.configRules,'operationAuditTimeout',[
            this.$formValidator.empty('结束时间','blur','number'),
          { validator:validateNumber(1,99), trigger:'blur',type:'number'}])
      }
    },

    submit(){
      this.$refs.configForm.validate(valid=>{
        if(valid){
          let param =  {...this.config}
          if(param.sendOrderType==1){
            delete param.matchType
          }
          this.isSubmitting=true;
          this.$api.property.saveRepairParameter(param).then(res=>{
            if(res.code===200){
              this.$message.success('保存成功');
            }
            this.isSubmitting=false;
          }).catch(err=>{
            this.isSubmitting=false;
          })
        }
      })
    },

    getRepairParameters(){
      this.$api.property.getRepairParameter().then(res=>{
        this.config = res.data;
        this.config.isOperationAudit?this.setStep(3):(this.config.isPropertyAudit?this.setStep(2):this.setStep(1));
      })
    },

    changeIsPay(val){
      !val && (this.config.payOffline = !val);
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-step__head.is-finish {
  color: $subjectColor;
  border-color: $subjectColor;
}
::v-deep .el-step__title.is-finish {
  color:$subjectColor;
}
.el-divider--horizontal{
  margin:18px 0;
}
.el-input,.el-select {
  width: 200px;
}
.el-step__description .el-input{
  width: 90px;
  padding-left: 27px;
}
.el-step__description label{
  width: 100px;
}
::v-deep .el-step__title{
  font-size: 14px;
  &.is-process{
    font-weight:normal;
    color:#C0C4CC !important;
  }
}
.repair-parameters-wrapper{
  .main{
    background: #fff;
    padding: 18px;
    border-radius: 2px;
    height: calc(100% - 100px);
    .form-block{
      .form-group{
        .left-label{
          width: 135px;
          display: inline-block;
          text-align: right;
          padding-right: 12px;
          margin-top: 18px;
        }
        .step-block{
          width: 800px;
          margin: 12px 0 15px 0;
          display: inline-block;
          vertical-align: top;
          .is-finish{
            .solid-ring{
              border: 1px solid $subjectColor;
              background:$subjectColor;
              &:before{
                border: 1px solid $subjectColor;
              }
            }
          }
          .is-process{
            .solid-ring{
              border: 1px solid #C0C4CC ;
              &:before{
                border: 1px solid #C0C4CC ;
              }
            }
          }
          .solid-ring{
            width: 8px;
            height: 8px;
            border: 1px solid #C0C4CC;
            background: #fff;
            border-radius: 50%;
            cursor: pointer;
            position: relative;

            &:before{
              content: '';
              position: absolute;
              top: -5px;
              left: -5px;
              width: 16px;
              height: 16px;
              border: 1px solid #C0C4CC;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .footer{
    text-align: center;
  }
}
</style>